//Mobile------------------------------------------------------------------------------------------------------

.heading-wrapper {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.sectionHeading {
	width: 100%;
	h2.sectionHeading__title {
		font-size: clamp(3rem,8.3333333333vw,120px);
		line-height: 0.85;
		color: var(--body-color);
		font-weight: 400;
		margin-bottom: 0;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
