//Mobile------------------------------------------------------------------------------------------------------
.contact-form {
	padding-block: 3rem calc(3rem - 10px)!important;
	color: var(--body-bg);
	.sectionHeading h2.sectionHeading__title {
		color: var(--body-bg);
		font-size: 2rem;
		line-height: 1.25;
		text-align: left;
	}

	button.send-button {
		border-radius: 0;
		border-width: 2px !important;
		font-size: 15px;
		padding: 24px 40px;
		font-weight: 400;
		transition: border 0s, background-color 0.2s, color 0.2s;
		border-color: var(--color-primary) !important;
		background-color: var(--color-primary) !important;
		text-transform: none;
		font-size: 1rem;
		&:hover {
			border-color: var(--color-primary) !important;
			color: var(--text-color) !important;
		}
		margin-top: 44px;
	}
	input,
	textarea {
		border-bottom: 1px solid var(--body-bg) !important;
		padding-bottom: 1rem !important;
		font-size: 1rem !important;
		&::placeholder {
			//color: var(--body-bg);
			font-size: 1rem;
		}
	}
	label {
		a {
			color: var(--body-bg);
		}
	}
	.form-checkbox .checkbox:hover input:checked ~ .checkbox__mark {
		background-color: var(--color-primary);
		border-radius: 0;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.contact-form {
		padding-block: 5rem calc(5rem - 10px)!important;
		.col-10 {
			width: 100%;
		}
		button.send-button {
			width: 100%;
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.contact-form {
		.sectionHeading h2.sectionHeading__title {
			font-size: 2.5rem;
		}
		.col-xl-10 {
			width: 100%;
			padding-inline: 8.75rem;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
	.contact-form {

	}
}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
