a,
button,
input {
	&:focus {
		outline: none;
	}
	&:focus-visible {
		outline: var(--outline-width) solid var(--outline-color);
	}
}
