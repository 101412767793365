//Mobile------------------------------------------------------------------------------------------------------
#popupConsent {
	color: var(--text-color);
	background-color: var(--body-bg);
	border: 1px solid var(--text-color);
	p {
		padding-inline: 0;
	}
	.btn {
		color: var(--text-color);
		border: 1px solid var(--text-color);
		padding: 8px 16px;
		width: 100%;
		display: block;
	}
	.col-md-4 {
		width: 100%;
		margin: 0;
	}
	.row {
		padding: 10px;
	}
	.accept_all {
		margin-bottom: 10px;
		.btn {
			background-color: var(--color-primary);
			border-color: var(--color-primary);
		}
	}
	#configureSection table td b,
	#configureSection table th b,
	h4 {
		color: var(--text-color);
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	#popupConsent {
		.col-md-4 {
			width: 50%;
			margin: 0;
		}
		.row {

		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
