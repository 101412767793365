/* SharpGrotesk-300 - latin */
@font-face {
	font-family: "SharpGrotesk";
	font-style: normal;
	font-weight: 300;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/sharpgrotesk-light20-webfont.woff") format("woff"); /* Modern Browsers */
}
/* SharpGrotesk-regular - latin */
@font-face {
	font-family: "SharpGrotesk";
	font-style: normal;
	font-weight: 400;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/sharpgrotesk-book20-webfont.woff") format("woff"); /* Modern Browsers */
}
/* SharpGrotesk-500 - latin */
@font-face {
	font-family: "SharpGrotesk";
	font-style: normal;
	font-weight: 500;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/sharpgrotesk-semibold20-webfont.woff") format("woff"); /* Modern Browsers */
}
/* SharpGrotesk-600 - latin */
@font-face {
	font-family: "SharpGrotesk";
	font-style: normal;
	font-weight: 600;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/sharpgrotesk-semibold20-webfont.woff") format("woff"); /* Modern Browsers */
}
/* SharpGrotesk-700 - latin */
@font-face {
	font-family: "SharpGrotesk";
	font-style: normal;
	font-weight: 700;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/sharpgrotesk-bold20-webfont.woff") format("woff"); /* Modern Browsers */
}
