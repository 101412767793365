//Mobile------------------------------------------------------------------------------------------------------
.col4-icons {
	background-color: var(--body-bg);
	font-size: 16px;
	padding-top: 0 !important;
	padding-bottom: 3rem!important;
	&.bg-primary {
		background-color: var(--color-primary);
	}
	.pt-60 {
		padding-top: 0 !important;
	}
	.rounded-full {
		&.bg-white {
			background-color: transparent;
			padding: 0 !important;
			width: auto !important;
			height: auto !important;
			display: block !important;
		}
	}
	.mt-30 {
		margin-top: 1.5rem !important;
	}
	.text-center {
		text-align: left;
		padding-inline: 0 !important;
	}
	.container {
		padding-inline: 1.5rem;
		max-width: 100%;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.col4-icons {
		padding-bottom: 5rem! important;
		.container {
			padding-inline: 9rem 6rem;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.col4-icons {
		.justify-center.y-gap-48 {
			flex-wrap: nowrap !important;
			justify-content: space-between !important;
		}
		.col-lg-3 {
			width: auto !important;
			flex-shrink: 1;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
