:root {
	//-colors
	--color-primary: #075687;
	--color-primary-hover: #075687;
	--color-secondary: #fff;
	--color-secondary-hover: #fff;
	--text-color: #fff;
	--border-color: #aaa;
	--border-color-light: #eee;
	--border-color-lighter: #fafafa;
	//-general
	--body-bg: #000;
	--body-color: var(--text-color);
	--link-color: var(--text-color);
	--border-radius: 8px;
	//-fonts
	--font-primary: "SharpGrotesk", sans-serif;
	--font-secondary: "SharpGrotesk", sans-serif;
	--font-mono: "Courier", monospace;
	//-typography
	--header-font: var(--font-secondary);
	--header-weight: 400;
	--header-color: var(--text-color);
	--header-line-height: 1.1;
	--header-margin-bottom: 0.15em;
	--h1-font-size: 4rem;
	--h2-font-size: 3rem;
	--h3-font-size: 2.5rem;
	--h4-font-size: 1.5rem;
	--h5-font-size: 1.25rem;
	--h6-font-size: 1rem;
	--p-font-size: 1.125rem;
	--p-margin-bottom: 1em;
	--p-line-height: 1.55555555555;
	--p-color: var(--text-color);
	--hr-size: 1px;
	--hr-color: var(--border-color);
	--hr-margin: 1rem;
	//-transition
	--transition-default: all 0.3s ease-in-out;
	//-icon
	--icon-size-sm: 16px;
	--icon-size: 24px;
	--icon-size-lg: 32px;
	--icon-size-xl: 40px;

	// focus outline
	--outline-color: orange;
	--outline-width: 2px;
}
//mobile
@include media-breakpoint-up(sm) {
	:root {

	}
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
	:root {

	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	:root {

	}
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	:root {

	}
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
	:root {

	}
}
//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
