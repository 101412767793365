//Mobile------------------------------------------------------------------------------------------------------
.header {
	.nav {
		margin-bottom: 0;
	}
	.js-nav-open {
		svg {
			stroke: var(--text-color);
		}
	}
	&.is-sticky {
		.js-nav-open {
			svg {
				stroke: var(--body-bg);
				stroke-width: 1px;
			}
		}
	}

	.js-nav-close {
		font-size: 18px;
		svg {
			stroke: var(--body-bg);
			stroke-width: 1px;
		}
	}
	.mobile__footer {
		border-top: none;
		padding-inline: 30px;
		p {
			color: var(--body-bg);
			margin-bottom: 0;
		}

	}
	.mobile__socials {
		display: none;
	}

	.menu {
		.nav {
			li {
				a {
					font-weight: 400;
					padding-left: 10px !important;
					font-size: 18px;
					&:before {
						content: none;
					}

				}

			}
		}
	}


}



//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Mobile-only ---------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.header {

		.header__bar {
			padding: 1.2rem 1.5rem;
		}

		.justify-between  {
			& > {
				.col-2 {
					width: 150px;
				}
			}
		}
		.mobile__footer {
			padding-inline: 20px;
		}
		.menu {
			.nav {
				li {
					a {
						padding-left: 0px !important;
					}
				}
			}
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.header {

		.justify-between  {
			justify-content: flex-end !important;
			& > {
				.col-2 {
					margin-right: auto;
				}
			}
		}

		.menu {
			.nav {
				li {
					a {
						font-size: 15px;
					}
				}
			}
		}

		.header__right {
			.button  {
				border-radius: 0;
				border-width: 2px !important;
				font-size: 15px;
				border-color: var(--text-color);
				padding: 24px 40px;
				background-color: var(--text-color);
				color: var(--color-primary) !important;
				font-weight: 400;
				transition: border 0s, background-color 0.2s, color 0.2s;
				&:hover {
					border-color: var(--text-color) !important;
					color: var(--text-color) !important;
					background-color: transparent !important;
				}
			}
		}

		&.is-sticky.-sticky-dark .header__bar .header__right a.button {
			border-color: var(--color-primary) !important;
			background-color: var(--color-primary) !important;
			&:hover {
				border-color: var(--color-primary) !important;
				color: var(--color-primary) !important;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.header.-type-2 .header__bar {
		padding-left: 40px;
		padding-right: 40px;
  }
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
