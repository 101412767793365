//Mobile------------------------------------------------------------------------------------------------------
.masthead.-type-3 {
	padding-bottom: 3rem;
	.masthead__title {
		font-size: 1.5rem;
		line-height: 1.2;
		font-weight: 400;
	}
	p {
		font-size: 1rem;
		line-height: 1.5;
		padding-top: 1rem !important;
		margin-bottom: 0;
	}
	.button.-outline-white {
		padding: 0;
		min-width: auto;
		border: none;
		border-radius: none;
		text-decoration: underline;
		font-size: 1.125rem;
		&:hover {
			background-color: transparent;
			color: var(--text-color) !important;
		}
		display: flex;
		align-items: center;
		&:after {
			margin-left: 1rem;
			content: url("data:image/svg+xml,%3Csvg width='29' height='20' viewBox='0 0 29 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.9556 16.1626C20.3115 14.2604 21.9109 12.5978 23.3683 11.5269H1V8.34028H23.264C22.5686 7.78608 21.8385 7.12797 21.1431 6.29667C19.6509 4.70334 18.3645 2.80115 17.6691 1H21.3546C21.6675 1.86594 22.1543 2.83579 22.8815 3.90956C24.2723 5.95029 26.2541 7.92463 28.3025 9.24086V10.762C27.2942 11.4201 26.2541 12.1822 25.2805 13.152C23.264 15.0196 21.7341 17.1325 21.1431 19H17.4605C17.843 18.0301 18.295 17.1325 18.9556 16.1626Z' stroke='white'/%3E%3C/svg%3E%0A");
		}
	}
	.gradient-content {
		background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
	}

	.container {
		padding-inline: 1.5rem;
		max-width: 100%;
	}

	.y-gap-64 {
		& > .col-xl-6 {
			padding-top: 0;
		}
	}

	.masthead__bg {
		img {
			position: absolute;
			inset: 0;
			object-position: top;
		}
	}

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-down(lg) {
	.masthead.-type-3 {
		padding-top: 20rem;
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.masthead.-type-3 {
		padding-block: 10rem;
		.gradient-content {
			background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
		}
		.masthead__content{
			max-width: 440px;
			padding-block: 0rem;
		}
		.masthead__title {
			font-size: 2.5rem;
			line-height: 1.2;
		}
		p {
			font-size: 1.125rem;
			line-height: 1.5555555555555556;
		}
		.masthead__bg {
			img {
				object-position: center;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
