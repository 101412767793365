@keyframes scrollLine {
	0% {transform: scaleY(0);}
	50% {transform: scaleY(1);}
}

//Mobile------------------------------------------------------------------------------------------------------
.fullscreenSlider {
	.gradient-content {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50.08%, #000000 100%);
	}
	h1 {
		font-size: clamp(2rem,4.4444444444444vw,4rem);
		line-height: 1.3125;
		text-align: left;
		max-width: 880px;
		margin-bottom: 0;
	}
	.absolute-full-center {
		&.items-center {
			justify-content: flex-start !important;
			align-items: flex-end !important;
			padding: 1.5rem 1.5rem 2rem;
		}
	}

	&.single-slide {
		.pagination,
		.nav.-slider {
			display: none;
		}
	}
	.scroll-hint {
		margin-top: 2rem;
		font-size: 1.125rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		.scroll-line {
			width: 1px;
			height: 2rem;
			background-color: var(--text-color);
			margin-bottom: 0.5rem;
			animation: scrollLine 2s forwards infinite;
			transform-origin: top center;
		}
	}
	.video-desk {
		display: none !important;
	}

	.hero-image-wrapper {
		.img-wrapper {
			img {
				object-fit: cover;
				position: absolute;
				inset:0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Tablet portrait only ---------------------------------------------------------------------------------------
@include media-breakpoint-only(md) {
	.fullscreenSlider {
		h1 {
			font-size: 3rem;
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.fullscreenSlider {
		h1 {
			width: 66%;
			line-height: 1.125;
		}
		.absolute-full-center {
			&.items-center {
				padding: 40px;
			}
		}
		.scroll-hint {
			position: absolute;
			right: 40px;
			bottom: 40px;
			.scroll-line {
				position: relative;
				top: -64px;
				height: 64px;
			}
			.scroll-text {
				transform: rotate(-90deg) translateX(-50%) translateY(-6px);
				position: absolute;
			}
		}
		.video-mod {
			display: none !important;
		}
		.video-desk {
			display: block !important;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.fullscreenSlider {

	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
