//Mobile------------------------------------------------------------------------------------------------------
.skiplinks {
	display: flex;
	background-color: black;
	justify-content: center;
	a {
		color: #fff;
		display: inline-block;
		padding: 16px;
		text-decoration: none;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}
