//Mobile------------------------------------------------------------------------------------------------------
.text-module {
	padding: 3rem 0;
	background-color: var(--body-bg);
	&.bg-primary {
		background-color: var(--color-primary);
	}
	h2 {
		font-size: 2rem !important;
		line-height: 1.25;
	}
	p {
		font-size: 1.125rem;
		line-height: 1.5555555555555556;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.container {
		padding-inline: 1.5rem;
		max-width: 100%;
	}
	.y-gap-40 {
		margin-block: 0;
		& > * {
			padding-block: 0;
		}
	}
	.logos {
		padding: 0;
		margin-top: 5rem;
		li {
			list-style-type: none;
			&:not(:last-child) {
				margin-bottom: 5rem;
			}
		}
		img {
			height: auto;
			max-width: 260px;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.text-module {
		padding: 5rem 0;
		p {
			font-size: 1.5rem;
			line-height: 1.75;
		}
		.container {
			padding-inline: 6rem;
		}
		.row {
			&.justify-center {
				justify-content: flex-start !important;
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.text-module {
		.container {
			margin-left: 120px;
			max-width: 75%;
			padding-inline: 1.25rem;
		}
		.logos {
			display: flex;
			justify-content: space-between;
			gap: 0 3rem;
			img  {
				width: 100%;
			}
			li {
				margin-bottom: 0 !important;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
