//Mobile------------------------------------------------------------------------------------------------------
.footer {
	padding-block: 5rem;
	.col-auto {
		img {
			margin-inline: auto;
			&.mt-20 {
				margin-top: 0 !important;
			}
		}
	}
	.py-30 {
		padding-block: 0 !important;
	}
	a {
		&:hover {
			color: var(--body-color);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.footer {
		img {
			max-width: 300px !important;
			margin-bottom: 2rem !important;
		}
		padding-block: 7.5rem 4.5rem;
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
